<template>
  <div id="videoprotect">
    <template v-if="$route.path === '/videoSafe/video'">
      <noOpen v-if="status == 2" :list="list"></noOpen>
      <div v-else class="main">
        <div class="allstatus">
          <div v-for="(item, index) in dateList" :key="index">
            <div class="one_status" v-if="item.status == 1">
              <div class="top">
                <div class="top_tu">
                  <img
                    src="../../assets/img/1.3.8/icon_bqbhbs备份.png"
                    alt=""
                  />
                </div>
                <div class="top_font">
                  视频保护方案已启用，可在直播和录播课章节中可选择该保护方案
                </div>
              </div>
              <div class="bottom">
                <div class="bottom_font1">{{ item.template_name }}</div>
                <div class="bottom_font2">
                  <img src="../../assets/img/1.3.8/icon_ykqi备份.png" alt="" />
                  已启用
                </div>
                <div class="bottom_center">
                  <div class="bottom_center_left">
                    <ul>
                      <li>
                        转码分辨率：
                        <span v-if="item.zm_spec == 1">高清720P</span>
                        <span v-if="item.zm_spec == 2">蓝光1080P</span>
                      </li>
                      <li>
                        允许观看终端：
                        <span>
                          {{ clientPlay(item) }}
                        </span>
                      </li>
                      <li v-if="item.is_float_words == 1">
                        跑马灯内容：{{ item.horse_content }}
                      </li>
                      <li v-if="item.is_float_words == 1">
                        字号大小：
                        <span v-if="item.font_zoom == 2">
                          {{ item.font_size }}px
                        </span>
                        <span v-else>随机缩放</span>
                      </li>
                      <li v-if="item.is_float_words == 1">
                        透明度：{{ item.opacity }}%
                      </li>
                    </ul>
                  </div>
                  <div class="divider" v-if="item.is_float_words == 1"></div>
                  <div
                    class="bottom_center_rigth"
                    v-if="item.is_float_words == 1"
                  >
                    <ul>
                      <li>跑马灯颜色：{{ item.font_color }}</li>
                      <li>
                        出现频率：
                        <span v-if="item.rate == 1">一直出现</span>
                        <span v-if="item.rate == 2">高频</span>
                        <span v-if="item.rate == 3">中频</span>
                        <span v-if="item.rate == 4">低频</span>
                      </li>
                      <li>
                        出现位置：
                        <span v-if="item.position == 1">从左往右</span>
                        <span v-if="item.position == 2">从右往左</span>
                        <span v-if="item.position == 3">从上往下</span>
                        <span v-if="item.position == 4">从下往上</span>
                        <span v-if="item.position == 5">随机位置</span>
                      </li>
                      <li>
                        出现速度：
                        <span v-if="item.speed == 1">快速</span>
                        <span v-if="item.speed == 2">中速</span>
                        <span v-if="item.speed == 3">低速</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="bottoms">
                  <el-button
                    v-if="showDeactivateBtn"
                    size="small"
                    type="warning"
                    @click="stopUse(item.template_id)"
                  >
                    停用
                  </el-button>
                  <el-button
                    v-if="showEditBtn"
                    size="small"
                    type="primary"
                    @click="edit(item.template_id)"
                  >
                    编辑
                  </el-button>
                  <!-- <el-button size="small" type="primary" @click="effect">
                    查看效果
                  </el-button> -->
                  <el-button
                    v-if="showDeleteBtn"
                    size="small"
                    type="danger"
                    @click="delUse(item.template_id)"
                  >
                    删除
                  </el-button>
                </div>
              </div>
            </div>

            <div class="two_status" v-if="item.status == 2">
              <div class="top">
                <div class="top_tu">
                  <img src="../../assets/img/1.3.8/icon_wyybs.png" alt="" />
                </div>
                <div class="top_font">视频保护方案未启用</div>
              </div>

              <div class="bottom">
                <div class="bottom_font1">{{ item.template_name }}</div>
                <div class="bottom_font2" style="color: #999999">
                  <img src="../../assets/img/1.3.8/icon_wqy.png" alt="" />
                  未启用
                </div>
                <div class="bottom_center">
                  <div class="bottom_center_left">
                    <ul>
                      <li>
                        转码分辨率：
                        <span v-if="item.zm_spec == 1">高清720P</span>
                        <span v-if="item.zm_spec == 2">蓝光1080P</span>
                      </li>
                      <li>
                        允许观看终端：
                        <span>
                          {{ clientPlay(item) }}
                        </span>
                      </li>
                      <li v-if="item.is_float_words == 1">
                        跑马灯内容：{{ item.horse_content }}
                      </li>
                      <li v-if="item.is_float_words == 1">
                        字号大小：
                        <span v-if="item.font_zoom == 2">
                          {{ item.font_size }}px
                        </span>
                        <span v-else>随机缩放</span>
                      </li>
                      <li v-if="item.is_float_words == 1">
                        透明度：{{ item.opacity }}%
                      </li>
                    </ul>
                  </div>
                  <div class="divider" v-if="item.is_float_words == 1"></div>
                  <div
                    class="bottom_center_rigth"
                    v-if="item.is_float_words == 1"
                  >
                    <ul>
                      <li>跑马灯颜色：{{ item.font_color }}</li>
                      <li>
                        出现频率：
                        <span v-if="item.rate == 1">一直出现</span>
                        <span v-if="item.rate == 2">高频</span>
                        <span v-if="item.rate == 3">中频</span>
                        <span v-if="item.rate == 4">低频</span>
                      </li>
                      <li>
                        出现位置：
                        <span v-if="item.position == 1">从左往右</span>
                        <span v-if="item.position == 2">从右往左</span>
                        <span v-if="item.position == 3">从上往下</span>
                        <span v-if="item.position == 4">从下往上</span>
                        <span v-if="item.position == 5">随机位置</span>
                      </li>
                      <li>
                        出现速度：
                        <span v-if="item.speed == 1">快速</span>
                        <span v-if="item.speed == 2">中速</span>
                        <span v-if="item.speed == 3">低速</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="bottoms">
                  <el-button
                    size="small"
                    type="primary"
                    @click="openUse(item.template_id)"
                  >
                    启用
                  </el-button>
                  <el-button
                    v-if="showEditBtn"
                    size="small"
                    type="primary"
                    @click="edit(item.template_id)"
                  >
                    编辑
                  </el-button>
                  <!-- <el-button size="small" type="primary" @click="effect">
                    查看效果
                  </el-button> -->
                  <el-button
                    v-if="showDeleteBtn"
                    size="small"
                    type="danger"
                    @click="delUse(item.template_id)"
                  >
                    删除
                  </el-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showCreateBtn" class="newprotect cp" @click="newly">
            <div class="newprotect_new">
              <div>
                <img src="../../assets/img/1.3.8/icon_tj备份.png" alt="" />
              </div>
              <div class="newprotect_font1">新建自定义保护方案</div>
              <div class="newprotect_font2">10项内容可自行定制</div>
            </div>
          </div>
        </div>
      </div>
      <copyrightprotect></copyrightprotect>
    </template>
    <router-view />
  </div>
</template>

<script>
import noOpen from '@/components/copyrightprotect/noOpen' //顶部开通
import copyrightprotect from '@/components/copyrightprotect/index' //轮播图
export default {
  name: 'videoprotect',
  data() {
    return {
      dateList: [],
      status: '',
      list: {
        status: 1,
        src: require('@/assets/img/1.3.8/icon_bqbhtb.png'),
        title: '数字版权保护系统',
        describe:
          '三项专利技术加持，集合视频保护、品牌保护、账号保护等3项技术…%追踪翻录视频的账号',
        button_name: '申请试用',
        money: 1999,
        strike: 3999,
        function_tu: require('@/assets/img/1.3.8/img_bqjs.jpg'),
        tip: '/年',
      },
    }
  },
  components: {
    copyrightprotect,
    noOpen,
  },

  computed: {
    showCreateBtn() {
      return this.$store.state.roots.includes(96)
    },

    showEditBtn() {
      return this.$store.state.roots.includes(97)
    },

    showDeleteBtn() {
      return this.$store.state.roots.includes(98)
    },

    showDeactivateBtn() {
      return this.$store.state.roots.includes(99)
    },
  },

  watch: {
    $route(to) {
      if (to.path === '/videoSafe/video') {
        this.getDate()
      }
    },
  },
  created() {
    this.status = this.$store.state.userInfo.jg_is_drm
    if (this.status == 1) {
      this.getDate()
    }
  },
  methods: {
    clientPlay({ is_pc_play, is_h5_play, is_client_play }) {
      var arr = []
      if (is_pc_play == 1) {
        arr.push('PC浏览器')
      }
      if (is_h5_play == 1) {
        arr.push('微信h5')
      }
      if (is_client_play == 1) {
        arr.push('PC客户端')
      }
      return arr.join(',')
    },
    // effect() {

    // },
    edit(val) {
      this.$router.push({
        path: '/videoSafe/video/newly1',
        query: { name: '编辑', status: 2, template_id: val },
      })
    },
    newly() {
      // if (this.dateList.length >= 10) {
      //    this.$root.prompt("最多可自行定制10项内容")
      //    return
      // }
      this.$router.push({
        path: '/videoSafe/video/newly1',
        query: { name: '新建', status: 1 },
      })
    },
    getDate() {
      this.$http({
        url: '/VideoSafeTemplate/getList',
        // data: {
        //   order_id: this.data_id
        // },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.dateList = data
          }
        },
        error: (error) => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },

    stopUse(val) {
      this.$confirm(
        '是否停用该方案？停用后，已转码加密的视频依然生效，跑马灯和终端限制功能失效',
        '停用方案',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.$http({
          url: '/VideoSafeTemplate/closeVideoSafe',
          data: {
            template_id: val,
            status: 2,
            type: 1,
          },
          callback: () => {
            this.getDate()
          },
        })
      })
    },

    openUse(val) {
      this.$http({
        url: '/VideoSafeTemplate/closeVideoSafe',
        data: {
          template_id: val,
          status: 1,
          type: 1,
        },
        callback: () => {
          this.getDate()
        },
      })
    },
    delUse(val) {
      this.$confirm('是否删除该方案？确定后无法恢复！', '删除方案', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.$http({
            url: '/VideoSafeTemplate/closeVideoSafe',
            data: {
              template_id: val,
              type: 2,
            },
            callback: ({ code }) => {
              if (code == 200) {
                this.getDate()
              }
            },
            error: ({ code }) => {
              if (code == 403) {
                this.$alert(
                  '该保护方案已关联有课程章节，无法删除！如需删除，请在章节管理中取消包含方案',
                  '温馨提示',
                  {
                    confirmButtonText: '知道了',
                    callback: () => {},
                  }
                )
              }
            },
          })
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang="scss" scoped>
#videoprotect {
  .main {
    margin-top: 20px;
    background-color: #fff;
    padding: 0 0px 29px 40px;
    .allstatus {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
    }
    .one_status,
    .two_status,
    .newprotect {
      margin-top: 29px;
      margin-right: 25px;
      width: 404px;
      height: 470px;
      background: #f5f5f5;
      box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      .top {
        height: 136px;
        background: #3299ff;
        border-radius: 6px 6px 0px 0px;
        display: flex;
        align-items: center;
        .top_tu {
          margin: 33px 20px;
        }
        .top_font {
          font-size: 14px;
          color: #ffffff;
          line-height: 24px;
          margin-right: 20px;
        }
      }
      .bottom {
        .bottom_font1 {
          margin: 14px 20px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 16px;
        }
        .bottom_font2 {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #18c062;
          line-height: 12px;
          margin-left: 20px;
          margin-bottom: 29px;
          img {
            margin-right: 10px;
          }
        }
        .bottom_center {
          height: 175px;
          font-size: 12px;
          color: #333333;
          line-height: 17px;
          margin: 0 20px 26px;
          display: flex;
          .bottom_center_left {
            ul {
              width: 175px;
              li {
                margin-bottom: 11px;
              }
            }
          }
          .divider {
            height: 100%;
            width: 1px;
            background: #e8e8e8;
          }
          .bottom_center_rigth {
            ul {
              margin-left: 20px;
              li {
                margin-bottom: 11px;
              }
            }
          }
        }
        .bottoms {
          width: 261px;
          margin: 0 auto;
          // margin-left: 27px;
          ::v-deep .el-button {
            width: 80px;
            height: 30px;
            line-height: 12px;
          }
        }
      }
    }
    .newprotect {
      .newprotect_new {
        width: 180px;
        margin: 0 auto;
        margin-top: 182px;
        img {
          margin: 0 auto;
          cursor: pointer;
        }
        .newprotect_font1 {
          font-size: 20px;
          color: #333333;
          line-height: 20px;
          margin-top: 20px;
        }
        .newprotect_font2 {
          text-align: center;
          font-size: 12px;
          color: #1b9d97;
          line-height: 12px;
          margin: 0 auto;
          margin-top: 10px;
        }
      }
    }
    .two_status {
      .top {
        background-color: #989898;
      }
    }
  }
  // .dialogVisible {
  //   ::v-deep .el-dialog {
  //     height: 416px;
  //   }
  //   ::v-deep .el-dialog__title {
  //     font-size: 14px;
  //     color: #333333;
  //     line-height: 19px;
  //   }
  //   ::v-deep .el-dialog__header {
  //     padding-top: 14px;
  //   }
  //   .dialogVisible_bottom {
  //     width: 168px;
  //     margin: 0 auto;
  //     .dialogVisible_bottom_font1 {
  //       margin-top: 40px;
  //       font-size: 16px;
  //       color: #4a4a4a;
  //       line-height: 21px;
  //       margin-bottom: 65px;
  //     }
  //     img {
  //       margin-left: 9px;
  //     }
  //   }
  // }
}
</style>
